<template>
    <div id="container">
        <div id="linhatopo">
        </div>
        <div id="cabecalho">
            <layoutTopo @ClickMenu="onCLickMenu(0)">
            </layoutTopo>
        </div>
        <div id="container2">
            <div id="menulateral" v-if="$store.state.menuLateral.showMenu">
                <layoutMenu>
                </layoutMenu>
            </div>
            <div id="menulateralicone" v-if="$store.state.menuLateral.showMenuIcone">
                <layoutMenuIcone></layoutMenuIcone>
            </div>
            <div id="telatrabalho">
                <Router-view>
                </Router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import layoutTopo           from '@/components/layouts/layoutTopo.vue';
    import layoutMenu           from '@/components/layouts/layoutMenu.vue';
    import layoutMenuIcone      from '@/components/layouts/layoutMenuIcone.vue';
    import {mapState}           from 'vuex';

    export default {
        components: {
            layoutTopo,
            layoutMenu,
            layoutMenuIcone,
        },
        data() {
            return {
                dados : [],
            }
        },
        methods: {
            onCLickMenu(opcao) {
                this.$store.commit('updateMiniMenu',opcao); 
                if (this.$_userValido) {
                    if (this.$_menuLateral) {               
                        this.$store.commit('updateShowMenu',false);
                        this.$store.commit('updateShowMenuIcone',true); 
                    } else {
                        this.$store.commit('updateShowMenu',true);
                        this.$store.commit('updateShowMenuIcone',false); 
                    }
                }
            },
        },
        mounted() {
            if (localStorage.user_id>0) {
                if (localStorage.user_valido    =='true'){
                    this.dados['valido']        = true;
                } else {
                    this.dados['valido']        = false;
                } 
                this.dados['id']                = parseInt(localStorage.user_id);
                this.dados['nmusuario']         = localStorage.user_nome;
                this.dados['idmunicipio']       = parseInt(localStorage.user_idmunicipio);
                this.dados['nmmunicipio']       = localStorage.user_nmmunicipio;
                this.dados['nivel']             = parseInt(localStorage.user_nivel);
                this.dados['tipousuario']       = parseInt(localStorage.user_tipousuario);
                this.dados['avatar']            = localStorage.user_avatar;

                this.dados['logo']              = localStorage.municipio_logo;
                this.dados['tppagamento']       = parseInt(localStorage.municipio_tppagamento);
                this.dados['ckbaixahodometro']  = parseInt(localStorage.municipio_ckbaixahodometro);

                this.dados['urlnome']           = localStorage.servidor_urlnome;
                if (localStorage.servidor_urltipo=='true'){
                    this.dados['urltipo']       = true;
                } else {
                    this.dados['urltipo']       = false;
                } 
                this.$store.commit('updateUsuario',this.dados);  
                this.$store.commit('updateShowMenu',true);
                this.$store.commit('updateShowMenuIcone',false);
            }
        },
        
        computed: {
            ...mapState({
                $_miniMenu_opcao:       state => state.menuLateral.miniMenu_opcao,
                $_menuLateral:          state => state.menuLateral.showMenu,
                $_menuLateralIcone:     state => state.menuLateral.showMenuIcone,
                $_userId:               state => state.user.user_id,   
                $_userValido:           state => state.user.user_valido,                 
            }),
        },
    }
</script>

<style scoped>
    #container {
        width: 100%;
        height: 700px;
        background-color: rgb(255, 255, 255);
        justify-content: center;
        padding-left: 4px;
        padding-right: 2px;
    }

    #container2 {
        width: 100%;
        height: 100%;
        background-color: rgb(191, 242, 191);
        display: flex;
        justify-content: center;
        margin-top: 5px;

    }

    #linhatopo {
        width: 100%;
        height: 1px;
        background-color: #737272;
    }

    #cabecalho {
        width: 100%;
        height: 65px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 10px #737272;
    }

    #menulateral {
        width: 330px;
        height: 100%;
        background-color: #F5F5F5;
    }

    #menulateralicone {
        width: 70px;
        height: 100%;
        background-color: #F5F5F5;
    }

    #telatrabalho {
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
    }
</style>