<template>
    <div>
        almoxarifado
    </div>
</template>

<script>
    export default{
        name: 'menuAlmoxarifado',
        components:{
        },
        data() {
            return {
            }
        },
    };
</script>

<style scoped>

</style>
