export default {
    beforeMount(el) {
      el.addEventListener('input', function (e) {
        let value = e.target.value.replace(/\D/g, '');
  
        if (value.length <= 10) {
          // Máscara para número de telefone fixo (48)3535-3535
          value = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
        } else {
          // Máscara para número de telefone celular (48)91010-2020
          value = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
        }
  
        e.target.value = value;
        const event = new Event('input', { bubbles: true });
        el.dispatchEvent(event);
      });
    }
  };
  