<template>
    <div>
        <ul 
            class       = "list-group"
            v-for       = "item in itens"
            :key        = "item.title"
            :to         = "item.to"
        >
            <div
                v-if    = "item.menu" 
                id      = "subtitulo">
                <br v-if= "item.first==false">
                <p>{{item.title}}</p>
            </div>

            <router-link 
                id="li_link" 
                :to="item.to" 
                v-if= "item.menu==false"
            >
                <a id="li_link" class="link-opacity-10-hover link-underline-light" href="">{{item.title}}</a>
            </router-link>
        </ul>
    </div>
</template>

<script>
    import {lerDadosGenericos}    from '@/repositories/rotinas/useChamadas_API';

    export default{
        name: 'menuConfiguracoes',
        components:{
        },
        data() {
            return {
                idusuario               : localStorage.user_id,
                itens                   : [],
            }
        },
        async created() {
            const params        = {idusuario: this.idusuario,  menu: 'HorasMaquinas'};
            const caminho       = "usuario.listamenu";
            const retorno       = await lerDadosGenericos(params, caminho);
            if (retorno.valido==false) {
                this.itens                  = [];
                this.exibirDados            = false;
            } else {
                this.itens                  = retorno['dados'];
            }
        },
    };
</script>

<style scoped>
    #subtitulo{
        font-size: 17px;
        font-weight: bold;
        color: #333;
        font-family: 'Verdana', 'sans-serif';
        opacity: 0.7;
        margin-top: -4px;
        padding-top: -2px;
        margin-left: 5px;
        margin-bottom: -2px;
        padding-bottom: -2px;
    }
    #li_link{
        font-size: 14px;
        color: black;
        font-family: 'Verdana', 'sans-serif';
        opacity: 0.9;
        margin-bottom: 4px;
        margin-left: 15px;
    }

    #li_link:hover{
        font-weight: bold;
    }
</style>
