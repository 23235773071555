import axios                from 'axios';
import {useLinksApi}        from '@/repositories/rotinas/useLinks_API.js';

export const lerDadosGenericos = async (tParams, tCaminho) => {
    const mCaminho      = useLinksApi(tCaminho, '');
    const params        = tParams;
    let   dados         = [];
    await axios.post(mCaminho, params)
    .then(response => {
        dados              = response.data;
    })
    .catch(error => {
        dados['msg']       = [error];
        dados['valido']    = false;
    });
    return dados; 
};

export const gravaDadosGenericos = async (tParams, tCaminho) => {
    const mCaminho      = useLinksApi(tCaminho, '');
    const params        = tParams;
    let   dados         = [];
    await axios.post(mCaminho, params)
    .then(response => {
        dados              = response.data;
    })
    .catch(error => {
        dados['msg']       = [error];
        dados['valido']    = false;
    });
    return dados;
};

