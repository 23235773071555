import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/teste2/:acao:id',
    name: 'teste2',
    component: () => import(/* webpackChunkName: "teste2View" */ '../views/teste2View.vue')
  },
  // PDF
      {
        path: '/exibePdf/:tipoPdf:id:novaAba',
        name: 'exibePdf',
        component: () => import(/* webpackChunkName: "exibePdf" */ '../views/pdf/exibePdf')
      },
      {
        path: '/pdf',
        name: 'fnPdf',
        component: () => import(/* webpackChunkName: "fnPdf" */ '../views/fnPdf.vue')
      },


  // GARAGEM
      // ORDEM DE SERVIÇO
      {
        path: '/osAgendaExecucao',
        name: 'osAgendaExecucao',
        component: () => import(/* webpackChunkName: "osAgendaExecucao" */ '../views/garagem/ordemdeservico/osAgendaExecucao.vue')
      },
      // DEFINIR EQUIPAMENTO
      {
        path: '/osDefinirEquipamento',
        name: 'osDefinirEquipamento',
        component: () => import(/* webpackChunkName: "osDefinirEquipamento" */ '../views/garagem/definirequipamento/osDefinirEquipamento.vue')
      },
      // MULTIPLOS EQUIPAMENTOS
      {
        path: '/osMultiplosEquipamentos',
        name: 'osMultiplosEquipamentos',
        component: () => import(/* webpackChunkName: "osMultiplosEquipamentos" */ '../views/garagem/multiplosequipamentos/osMultiplosEquipamentos.vue')
      },
      // MULTIPLOS EQUIPAMENTOS EDIT
      {
        path: '/osMultiplosEquipamentosEdit/:acao:id',
        name: 'osMultiplosEquipamentosEdit',
        component: () => import(/* webpackChunkName: "osMultiplosEquipamentosEdit" */ '../views/garagem/multiplosequipamentos/osMultiplosEquipamentosEdit.vue')
      },
      // MUDAR EQUIPAMENTO
      {
        path: '/osMudarEquipamento',
        name: 'osMudarEquipamento',
        component: () => import(/* webpackChunkName: "osMudarEquipamento" */ '../views/garagem/mudarequipamento/osMudarEquipamento.vue')
      },

  // ORDEM DE SERVICO
      // ORDEM DE SERVIÇO
      {
        path: '/osPesquisa',
        name: 'osPesquisa',
        component: () => import(/* webpackChunkName: "osPesquisa" */ '../views/ordemdeservico/pesquisa/osPesquisa.vue')
      },
      {
        path: '/osDetalhar/:acao:id',
        name: 'osDetalhar',
        component: () => import(/* webpackChunkName: "osDetalhar" */ '../views/ordemdeservico/pesquisa/osDetalhar.vue')
      },
      {
        path: '/osHistoricoExecucao/:acao:id',
        name: 'osHistoricoExecucao',
        component: () => import(/* webpackChunkName: "osHistoricoExecucao" */ '../views/ordemdeservico/historicoexecucao/historicoExecucao.vue')
      },
      {
        path: '/osEmissaoProdutor',
        name: 'osEmissaoProdutor',
        component: () => import(/* webpackChunkName: "osEmissaoProdutor" */ '../views/ordemdeservico/emissao/osemissaoProdutor.vue')
      },
      {
        path: '/osEmissaoIncluir/:acao:id',
        name: 'osEmissaoIncluir',
        component: () => import(/* webpackChunkName: "osEmissaoIncluir" */ '../views/ordemdeservico/emissao/osemissaoIncluir.vue')
      },
      {
        path: '/osEmissaoExecutada/:acao:id',
        name: 'osEmissaoExecutada',
        component: () => import(/* webpackChunkName: "osEmissaoExecutada" */ '../views/ordemdeservico/emissao/osemissaoExecutada.vue')
      },
      {
        path: '/osCancelar',
        name: 'osCancelar',
        component: () => import(/* webpackChunkName: "osCancelar" */ '../views/ordemdeservico/cancelar/osCancelar.vue')
      },
      {
        path: '/osCancelarDetalhar/:acao:id',
        name: 'osCancelarDetalhar',
        component: () => import(/* webpackChunkName: "osCancelarDetalhar" */ '../views/ordemdeservico/cancelar/osCancelarDetalhar.vue')
      },
      {
        path: '/osAnular',
        name: 'osAnular',
        component: () => import(/* webpackChunkName: "osAnular" */ '../views/ordemdeservico/anular/osAnular.vue')
      },
      {
        path: '/osBaixaManual',
        name: 'osBaixaManual',
        component: () => import(/* webpackChunkName: "osBaixaManual" */ '../views/ordemdeservico/baixa/osBaixaManual.vue')
      },
      {
        path: '/osBaixaManualHodometro/:acao:id',
        name: 'osBaixaManualHodometro',
        component: () => import(/* webpackChunkName: "osBaixaManualHodometro" */ '../views/ordemdeservico/baixa/osBaixaManualHodometro.vue')
      },
      {
        path: '/osBaixaManualRelogio/:acao:id',
        name: 'osBaixaManualRelogio',
        component: () => import(/* webpackChunkName: "osBaixaManualRelogio" */ '../views/ordemdeservico/baixa/osBaixaManualRelogio.vue')
      },
      

      {
        path: '/osSaldodeHoras',
        name: 'osSaldodeHoras',
        component: () => import(/* webpackChunkName: "osSaldodeHoras" */ '../views/municipes/pesquisa/osSaldodeHoras.vue')
      },


      // ORDEM DE SERVIÇO INDIVIDUAL
      {
        path: '/osIndividual',
        name: 'osIndividual',
        component: () => import(/* webpackChunkName: "osIndividual" */ '../views/ordemdeservico/individual/osindividualLista.vue')
      },
      {
        path: '/osIndividualEdit/:acao:id',
        name: 'osIndividualEdit',
        component: () => import(/* webpackChunkName: "osIndividualEdit" */ '../views/ordemdeservico/individual/osindividualEdit.vue')
      },
      {
        path: '/osIndividualIncluir/:acao:id',
        name: 'osIndividualIncluir',
        component: () => import(/* webpackChunkName: "osIndividualIncluir" */ '../views/ordemdeservico/individual/osindividualIncluir.vue')
      },


  // MUNICÍPES
      // LOCALIDADES
      {
        path: '/localidades',
        name: 'localidades',
        component: () => import(/* webpackChunkName: "localidades" */ '../views/municipes/localidadesLista.vue')
      },
      {
        path: '/localidadesEdit/:acao:id',
        name: 'localidadesEdit',
        component: () => import(/* webpackChunkName: "localidadesEdit" */ '../views/municipes/localidadesEdit.vue')
      },
      // MUNICIPES
      {
        path: '/municipes',
        name: 'municipes',
        component: () => import(/* webpackChunkName: "municipes" */ '../views/municipes/municipesLista.vue')
      },
      {
        path: '/municipesEdit/:acao:id',
        name: 'municipesEdit',
        component: () => import(/* webpackChunkName: "municipesEdit" */ '../views/municipes/municipesEdit.vue')
      },
  
  
  
  
  // USUÁRIOS
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "loginUsuario" */ '../views/usuarios/loginUsuario.vue')
  },
  {
    path: '/logoff',
    name: 'logoff',
    component: () => import(/* webpackChunkName: "logoffUsuario" */ '../views/usuarios/logoffUsuario.vue')
  },
  {
    path: '/telaEmpty',
    name: 'telaEmpty',
    component: () => import(/* webpackChunkName: "telaEmpty" */ '../views/telaEmpty.vue')
  },
  {
    path: '/usuarioLista',
    name: 'usuarioLista',
    component: () => import(/* webpackChunkName: "usuarioLista" */ '../views/usuarios/usuarioLista.vue')
  },
  {
    path: '/usuarioPerfil',
    name: 'usuarioPerfil',
    component: () => import(/* webpackChunkName: "usuarioPerfil" */ '../views/usuarios/usuarioPerfil.vue')
  },  
  {
    path: '/usuarioEdit/:acao:id',
    name: 'usuarioEdit',
    component: () => import(/* webpackChunkName: "usuarioEdit" */ '../views/usuarios/usuarioPerfil.vue')
  },

  // BOLETOS
  {
    path: '/fnBoletosLista',
    name: 'fnBoletosLista',
    component: () => import(/* webpackChunkName: "fnBoletosLista" */ '../views/financeiro/boletos/boletosLista.vue')
  },
  {
    path: '/fnBoletosEdit/:acao:id',
    name: 'fnBoletosEdit',
    component: () => import(/* webpackChunkName: "fnBoletosEdit" */ '../views/financeiro/boletos/boletosEdit.vue')
  },
  {
    path: '/fmBoletosConfirmarPagto',
    name: 'fmBoletosConfirmarPagto',
    component: () => import(/* webpackChunkName: "fmBoletosConfirmarPagto" */ '../views/financeiro/boletos/boletosConfirmarPagto.vue')
  },
  {
    path: '/fnBoletosConfirmarPagtoEdit/:acao:idos:idpagamento:parcela',
    name: 'fnBoletosConfirmarPagtoEdit',
    component: () => import(/* webpackChunkName: "fnBoletosConfirmarPagtoEdit" */ '../views/financeiro/boletos/boletosConfirmarPagtoEdit.vue')
  },
  {
    path: '/fnBoletosInadimplmentes',
    name: '/fnBoletosInadimplmentes',
    component: () => import(/* webpackChunkName: "/fnBoletosInadimplmentes" */ '../views/financeiro/boletos/boletosInadimplentes.vue')
  },

  // PAGAMENTOS
  {
    path: '/pagamentoEdit/:acao:id',
    name: 'pagamentoEdit',
    component: () => import(/* webpackChunkName: "fnBoletosEdit" */ '../views/financeiro/pagamento/pagamentoEdit.vue')
  },


// CONFIGURAÇÕES
  // MUNICIPIO
  {
    path: '/confMunicipio',
    name: 'confMunicipio',
    component: () => import(/* webpackChunkName: "confMunicipio" */ '../views/configuracao/municipioEdit.vue')
  },  
  // EQUIPAMENTOS
  {
    path: '/confEquipamentos',
    name: 'confEquipamentos',
    component: () => import(/* webpackChunkName: "confEquipamentos" */ '../views/configuracao/equipamentoLista.vue')
  },
  {
    path: '/confEquipamentosEdit/:acao:id',
    name: 'confEquipamentosEdit',
    component: () => import(/* webpackChunkName: "confEquipamentosEdit" */ '../views/configuracao/equipamentoEdit.vue')
  },
  // OPERADOR
  {
    path: '/confOperador',
    name: 'confOperador',
    component: () => import(/* webpackChunkName: "confOperador" */ '../views/configuracao/operadorLista.vue')
  },
  {
    path: '/confOperadorEdit/:acao:id',
    name: 'confOperadorEdit',
    component: () => import(/* webpackChunkName: "confOperadorEdit" */ '../views/configuracao/operadorEdit.vue')
  },
  // SERVIÇOS
  {
    path: '/confServicos',
    name: 'confServicos',
    component: () => import(/* webpackChunkName: "confServicos" */ '../views/configuracao/servicosLista.vue')
  },
  {
    path: '/confServicosEdit/:acao:id',
    name: 'confServicosEdit',
    component: () => import(/* webpackChunkName: "confServicosEdit" */ '../views/configuracao/servicosEdit.vue')
  },
  // REGIÕES
  {
    path: '/confRegiao',
    name: 'confRegiao',
    component: () => import(/* webpackChunkName: "confRegiao" */ '../views/configuracao/regiaoLista.vue')
  },
  {
    path: '/confRegiaoEdit/:acao:id',
    name: 'confRegiaoEdit',
    component: () => import(/* webpackChunkName: "confRegiao" */ '../views/configuracao/regiaoEdit.vue')
  },
  // GRUPO DE EQUIPAMENTOS
  {
    path: '/confGrupoEquip',
    name: 'confGrupoEquip',
    component: () => import(/* webpackChunkName: "confGrupoEquip" */ '../views/configuracao/grupoEquipLista.vue')
  },
  {
    path: '/confGrupoEquipEdit/:acao:id',
    name: 'confGrupoEquipEdit',
    component: () => import(/* webpackChunkName: "confGrupoEquipEdit" */ '../views/configuracao/grupoEquipEdit.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
