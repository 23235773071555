<template>
    <div class="row" id="rowmenu">
        <div class="col-2">
            <button 
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(1)"
            >
                <img 
                    src     = "@/assets/imagens/icones/clipboard-text-clock-outline.svg" 
                    title   = "Horas Máquinas"
                    width   = "25px"
                    height  = "25px"
                >
            </button>
        </div>
        <div class="col-2">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(2)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/tractor-variant.svg" 
                    title   = "Garagem"
                    width   = "25px"
                    height  = "25px"
                >
            </button>
        </div>
        <div class="col-2">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(3)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/excavator.svg" 
                    title   = "Obras"
                    width   = "25px"
                    height  = "25px"
                >
            </button>
        </div>        
        <div class="col-2">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(7)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/logoPessoa.svg" 
                    title   = "Munícipes"
                    width   = "30px"
                    height  = "30px"
                >
            </button>
        </div>
        <div class="col-2">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(4)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/deskphone.svg" 
                    title   = "Almoxarifado"
                    width   = "25px"
                    height  = "25px"
                >
            </button>
        </div>
        <div class="col-2">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(5)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/currency-usd.svg" 
                    title   = "Financeiro"
                    width   = "25px"
                    height  = "25px"
                >
            </button>
        </div>
        <div class="col-2">
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(6)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/cog-outline.svg" 
                    title   = "Configurações"
                    width   = "25px"
                    height  = "25px"
                >
            </button>
        </div>
    </div>  
    <hr id="linha_hr">
    <div class="mt-2">
        <menuHorasMaquinas  v-if="$_miniMenu_opcao === 1"></menuHorasMaquinas>
        <menuGaragem        v-if="$_miniMenu_opcao === 2"></menuGaragem>
        <menuAlmoxarifado   v-if="$_miniMenu_opcao === 4"></menuAlmoxarifado>
        <menuFinanceiro     v-if="$_miniMenu_opcao === 5"></menuFinanceiro>
        <menuConfiguracoes  v-if="$_miniMenu_opcao === 6"></menuConfiguracoes>
        <menuPessoas        v-if="$_miniMenu_opcao === 7"></menuPessoas>
    </div>
</template>

<script>
    import menuHorasMaquinas    from '@/components/menu/menuHorasMaquinas.vue';
    import menuGaragem          from '@/components/menu/menuGaragem.vue';
    import menuAlmoxarifado     from '@/components/menu/menuAlmoxarifado.vue';
    import menuFinanceiro       from '@/components/menu/menuFinanceiro.vue';
    import menuConfiguracoes    from '@/components/menu/menuConfiguracoes.vue';
    import menuPessoas          from '@/components/menu/menuPessoas.vue';
    import {mapState}           from 'vuex';

    export default {
        components: {
            menuAlmoxarifado,
            menuFinanceiro,
            menuConfiguracoes,
            menuPessoas,
            menuHorasMaquinas,
            menuGaragem,
        },
        data() {
            return {
                menuHorasMaquinas : true,
                menuGaragem       : true,
                menuObras         : true,
                menuPessoas       : true,
                menuAlmoxarifado  : true,
                menuFinanceiro    : true,
                menuConfiguracoes : true,
            }
        },
        methods: {
            onClick(evt){
                this.$store.commit('updateMiniMenu',evt);
            }
        },
        computed: {
            ...mapState({
                $_miniMenu_opcao:       state => state.menuLateral.miniMenu_opcao,
                $_menuLateral:          state => state.menuLateral.showMenu,
                $_menuLateralIcone:     state => state.menuLateral.showMenuIcone,
            }),
        },


    };
</script>

<style scoped>
    #rowmenu{
        width: 250px;
    }
    #linha_hr{
        margin-top: 0px;
        height: 1px;
        border-top: 1px solid rgb(136, 135, 135);
    }    
</style>