import { createApp }        from 'vue'
import { createStore }      from 'vuex'
import App                  from './App.vue'
import router               from './router'
import './assets/css/global.css'
import cpfMask              from '@/repositories/diretives/cpfMask'
import cepMask              from '@/repositories/diretives/cepMask'
import phoneMask            from '@/repositories/diretives/phoneMask'
import numberMask           from '@/repositories/diretives/numberMask'

const store = createStore({
    state: {
        user: {
            user_valido                 : false,
            user_id                     : 0,           
            user_nome                   : '',           
            user_idmunicipio            : 0,
            user_nmmunicipio            : '',
            user_nivel                  : 0,
            user_tipousuario            : 0,
            user_avatar                 : 'account-circle.svg',
        },
        municipio: {
            municipio_logo              : 'wss.jpg',
            municipio_tppagamento       : 1,
            municipio_ckbaixahodometro  : 0,
        },
        servidor: {
            //servidor_url_base           : 'http://127.0.0.1:8000/api/',
            //servidor_url_base           : 'https://sgsim.tec.br/public/api/',
            servidor_url_base           : '',
            servidor_urlnome            : '',
            servidor_urltipo            : true,
        },
        menuLateral: {
            miniMenu_opcao              : 0,
            showMenu                    : false,
            showMenuIcone               : false,
        }
    },

    mutations: {
        updateMiniMenu(state, value){
            state.menuLateral.miniMenu_opcao    = value                
        },
        updateShowMenu(state, value){
            state.menuLateral.showMenu          = value    
        },
        updateShowMenuIcone(state, value){
            state.menuLateral.showMenuIcone     = value                
        },
        updateUsuario(state, value){
            const dados_user                            = value;
            state.user.user_valido                      = dados_user['valido'];
            state.user.user_id                          = dados_user['id'];
            state.user.user_nome                        = dados_user['nmusuario'];
            state.user.user_idmunicipio                 = dados_user['idmunicipio'];
            state.user.user_nmmunicipio                 = dados_user['nmmunicipio'];
            state.user.user_nivel                       = dados_user['nivel'];
            state.user.user_tipousuario                 = dados_user['tipousuario'];
            state.user.user_avatar                      = dados_user['avatar'];

            state.municipio.municipio_logo              = dados_user['logo'];
            state.municipio.municipio_tppagamento       = dados_user['tppagamento'];
            state.municipio.municipio_ckbaixahodometro  = dados_user['ckbaixahodometro'];

            state.servidor.servidor_urlnome             = dados_user['urlnome'];
            state.servidor.servidor_urltipo             = dados_user['urltipo'];
        }
    }
})

createApp(App)
    .directive('cpf', cpfMask)
    .directive('cep', cepMask)
    .directive('phone', phoneMask)
    .directive('numero', numberMask)
    .use(store)
    .use(router)
    .mount('#app')
