<template>
    <div class="row">
        <div>
            <button  
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(1)"
            >
                <img 
                    src     = "@/assets/imagens/icones/clipboard-text-clock-outline.svg" 
                    title   = "Horas Máquinas"
                    width   = "35px"
                    height  = "35px"
                >
            </button>
            <br>
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(2)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/tractor-variant.svg" 
                    title   = "Garagem"
                    width   = "35px"
                    height  = "35px"
                >
            </button>
            <br>
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(3)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/excavator.svg" 
                    title   = "Obras"
                    width   = "35px"
                    height  = "35px"
                >
            </button>
            <br>
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(4)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/deskphone.svg" 
                    title   = "Almoxarifado"
                    width   = "35px"
                    height  = "35px"
                >
            </button>
            <br>
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(5)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/currency-usd.svg" 
                    title   = "Financeiro"
                    width   = "35px"
                    height  = "35px"
                >
            </button>
            <br>
            <button
                class="btn btn-outline-light"
                @click.stop.prevent  = "onClick(6)"
            >
                <img 
                    class   = "h-auto max-w-full" 
                    src     = "@/assets/imagens/icones/cog-outline.svg" 
                    title   = "Configurações"
                    width   = "35px"
                    height  = "35px"
                >
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
            }
        },
        methods: {
            onClick(evt){
                this.$store.commit('updateMiniMenu',evt);
                this.$store.commit('updateShowMenu',true);
                this.$store.commit('updateShowMenuIcone',false); 
            }
        },
    };
</script>

<style scoped>
    #linha_hr{
        width: 98%;
        height: 1px;
        border-top: 1px solid rgb(136, 135, 135);
    }    
</style>